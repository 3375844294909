/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, Avatar, ProgressBar, TextField } from 'mw-style-react';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DELETE_USER, SEARCH_USERS, GET_USERS_SUGGESTION, DEBOUNCE_MS } from 'constants';
import CustomBanner from '../../../CustomBanner';
import IconButton from '../../../IconButton';
import GroupIcon from '../../../../images/group-icon.svg';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import { getContrastColor } from '../../../../utils/helperFunctions';

class RemoveUserFromWorkspace extends PureComponent {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      isSubmit: false,

      userList: [],
      hasMore: true,
      offset: 0,

      searchName: '',
      selectedUsers: [],
      focus: false,
      searchLoading: false
    };

    this.timer = null;
    this.listRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.wrapperRef = React.createRef();

    this.getSuggestion(data);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getSuggestion(data) {
    const { dispatch } = this.props;
    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          limit: 20,
          offset: 0,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: data,
              offset: 0,
              hasMore,
              searchLoading: false
            }));
          }
        }
      }
    });
  }

  handleClickOutside(event) {
    const { focus } = this.state;

    if (
      focus &&
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        focus: false
      });
    }
  }

  fetchMore() {
    const { dispatch, data } = this.props;
    const { offset, searchName } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          limit: 20,
          offset: offset + 20,
          search: searchName,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: [...prev.userList, ...data],
              offset: prev.offset + 20,
              hasMore
            }));
          }
        }
      }
    });
  }

  setfocus() {
    this.setState({
      focus: true
    });
  }

  addToActive(user) {
    const { data } = this.props;
    const { id } = data || {};

    if (id !== user.id) {
      this.setState(
        prevState => {
          const userAlreadySelected = prevState.selectedUsers.some(su => su.id === user.id);

          if (!userAlreadySelected) {
            return {
              ...prevState,
              searchName: '',
              focus: false,
              selectedUsers: [...prevState.selectedUsers, user]
            };
          }

          return {
            ...prevState,
            searchName: '',
            focus: false
          };
        },
        () => {
          this.getSuggestion(data);
        }
      );
    }
  }

  handleRemoveItem2(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers: state.selectedUsers.filter(u => u.id !== id)
    }));
  }

  handleOnChangeSearch(e) {
    const { dispatch, data } = this.props;
    const { workspaceId } = data || {};
    const { value } = e;
    this.setState({
      searchName: value,
      offset: 0,
      hasMore: true,
      searchLoading: true
    });

    clearTimeout(this.timer);
    if (this.listRef.current) {
      this.listRef.current.scrollTop = 0;
    }

    this.timer = setTimeout(() => {
      if (value.length < 2) {
        this.getSuggestion(data);
      }

      if (value.length > 1) {
        dispatch({
          type: SEARCH_USERS.REQUEST,
          payload: {
            params: {
              workspaceId,
              query: value,
              limit: 20
            },
            callback: (result, data, hasMore) => {
              if (result === 'success' && data) {
                this.setState({
                  userList: data,
                  searchLoading: false,
                  hasMore
                });
              }
            }
          }
        });
      } else {
        this.setState({
          userList: []
        });
      }
    }, DEBOUNCE_MS);
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { selectedUsers } = this.state;
    const propOwnerIds = selectedUsers.map(s => s.id);

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: DELETE_USER.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          userId: data.id,
          propOwnerIds
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit, searchName, selectedUsers, userList, focus, hasMore, searchLoading } =
      this.state;
    const {
      data: { name, id }
    } = this.props;

    const filteredUserList = userList.filter(u => !selectedUsers.some(el => el.id === u.id));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.btnRemoveUserTitle)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Are you sure you want to remove user <strong>{name}</strong> from workspace? <br />
              <div style={{ color: 'rgba(126, 138, 154, 1)' }}>
                You’ll be able to add him/her later.
              </div>
            </div>
            <div>
              <CustomBanner text={this.i(mes.bannerCautionRemoveUser)} color="orange" />
            </div>
          </div>
          <div styleName="sModal.modal__section">
            <div style={{ position: 'relative' }}>
              <div styleName="sModal.modal__input__label">
                Matching access rights to all users items
              </div>
              <TextField
                styleName="sModal.modal__input"
                value={searchName}
                onChange={this.handleOnChangeSearch.bind(this)}
                placeholder="Search by name or email"
                leftIcon="search"
                length={255}
                bordered
                id="searchName"
                visibility={isSubmit ? 'disabled' : 'visible'}
                onFocus={this.setfocus.bind(this)}
              />
              {focus && filteredUserList.length ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  <ul id="scrollableModal" ref={this.listRef}>
                    <InfiniteScroll
                      dataLength={filteredUserList.length}
                      next={this.fetchMore.bind(this)}
                      hasMore={hasMore}
                      style={{ overflow: 'visible' }}
                      loader={
                        <div styleName="modal__workspace__suggestion__loader">
                          <ProgressBar />
                        </div>
                      }
                      scrollableTarget="scrollableModal"
                    >
                      {searchLoading && (
                        <div styleName="modal__workspace__suggestion__list-loader">
                          <ProgressBar />
                        </div>
                      )}
                      {filteredUserList.map(u => (
                        <li
                          key={u.id}
                          styleName={cn({
                            'sModal.listItem__disabled': u.id === id
                          })}
                        >
                          <div
                            styleName="sModal.modal__input__searchItemControl"
                            role="button"
                            tabIndex={0}
                            onClick={() => this.addToActive(u)}
                            onKeyDown={() => this.addToActive(u)}
                          >
                            <Avatar
                              styleName="modal__small__avatar"
                              size="small"
                              src={u.photo}
                              bgColor={u.color}
                              label={u.name}
                              textColor={getContrastColor(u.color)}
                            />
                            <span>{u.name}</span>
                          </div>
                        </li>
                      ))}
                    </InfiniteScroll>
                  </ul>
                </div>
              ) : null}

              {!filteredUserList.length && searchName.length > 1 ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  {searchLoading ? (
                    <div styleName="modal__workspace__suggestion__list-loader is-solid">
                      <ProgressBar />
                    </div>
                  ) : (
                    <div styleName="modal__workspace__suggestion__list-no-data">
                      No results for &quot;{searchName}&quot;
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div>
              <div styleName="sModal.modal__list__items">
                {selectedUsers.length ? (
                  <ul>
                    {selectedUsers.map(u => (
                      <li key={u.id}>
                        <div styleName="sModal.modal__list__items__element">
                          <div styleName="sModal.modal__list__items__element__group">
                            <Avatar
                              styleName="modal__small__avatar"
                              size="small"
                              src={u.photo}
                              bgColor={u.color}
                              label={u.name}
                              textColor={getContrastColor(u.color)}
                            />
                            <span>{u.name}</span>
                          </div>
                          <IconButton icon="close" onClick={() => this.handleRemoveItem2(u)} />
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div styleName="sModal.modal__list__empty">
                    <GroupIcon />
                    <span>Choose users from the list</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnRemoveUser)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
                red__disabled: isSubmit || !selectedUsers.length
              })}
              visibility={isSubmit || !selectedUsers.length ? 'disabled' : 'visible'}
              disabled={isSubmit || !selectedUsers.length}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

RemoveUserFromWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

RemoveUserFromWorkspace.contextTypes = {
  intl: intlShape
};

export default injectIntl(RemoveUserFromWorkspace);
